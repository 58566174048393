import React from "react";
import { Col } from "react-bootstrap";

export default function TextBlock(props) {
  return (
    <Col className="feature">
      <h3 className="title">{props.header}</h3>
      <p>{props.text}</p>
    </Col>
  );
}
