import React from "react";
import { Row } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import CallToActionBlock from "./partials/ContentfulCallToActionBlock";
import ListItemBlock from "./partials/ContentfulListItemBlock";
import TextBlock from "./partials/ContentfulTextBlock";

export default function FeatureBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          feature: allContentfulFeatureBlock {
            nodes {
              contentArea {
                ... on Node {
                  ... on ContentfulCallToActionBlock {
                    id
                    header
                    subheader
                    buttonText
                    buttonLink {
                      slug
                    }
                    text {
                      text
                    }
                    internal {
                      type
                    }
                  }
                  ... on ContentfulListItemBlock {
                    id
                    header
                    icon {
                      file {
                        url
                      }
                    }
                    text {
                      text
                    }
                    reviewer
                    internal {
                      type
                    }
                  }
                  ... on ContentfulTextBlock {
                    id
                    header
                    text {
                      text
                    }
                    internal {
                      type
                    }
                  }
                }
              }
              background
              id
            }
          }
        }
      `}
      render={(data) => {
        return data.feature.nodes.map((node) => {
          if (node.id === props.id) {
            return (
              <div
                key={`node-${node.id}`}
                className={`feature-block ${
                  node.background === "Light" ? "bg-light" : "bg-dark"
                }`}
              >
                <Row>
                  {node.contentArea.map((block) => {
                    if (block.internal.type === "ContentfulCallToActionBlock") {
                      return (
                        <CallToActionBlock
                          id={block.id}
                          key={`cta-${block.id}`}
                          header={block.header != null ? block.header : ""}
                          subheader={
                            block.subheader != null ? block.subheader : ""
                          }
                          text={block.text != null ? block.text.text : ""}
                          buttonText={
                            block.buttonText != null ? block.buttonText : ""
                          }
                          buttonLink={
                            block.buttonLink != null
                              ? `Blogs/${block.buttonLink.slug}`
                              : "#"
                          }
                        />
                      );
                    } else if (
                      block.internal.type === "ContentfulListItemBlock"
                    ) {
                      return (
                        <ListItemBlock
                          id={block.id}
                          key={`cta-${block.id}`}
                          header={block.header != null ? block.header : ""}
                          icon={block.icon != null ? block.icon.file.url : ""}
                          text={block.text != null ? block.text.text : ""}
                          reviewer={
                            block.reviewer != null ? block.reviewer : ""
                          }
                        />
                      );
                    } else if (block.internal.type === "ContentfulTextBlock") {
                      return (
                        <TextBlock
                          id={block.id}
                          key={`cta-${block.id}`}
                          header={block.header != null ? block.header : ""}
                          text={block.text != null ? block.text.text : ""}
                        />
                      );
                    }
                  })}
                </Row>
              </div>
            );
          }
        });
      }}
    />
  );
}
