import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function ImageTextBlock() {
  return (
    <StaticQuery
      query={graphql`
        {
          blocks: allContentfulImageWithTextBlock {
            nodes {
              id
              header
              icon {
                file {
                  url
                }
              }
              image {
                fluid {
                  src
                }
              }
              text {
                json
              }
            }
          }
        }
      `}
      render={(data) => {
        return data.blocks.nodes.map((node) => (
          <Row className="image-block" key={node.id}>
            {/* <!-- "reverse" in class as an option--> */}
            <Col xs={12} md={6} className="text-wrapper">
              <div className="content">
                <img
                  className="icon"
                  src={node.icon.file.url}
                  alt="cloud icon"
                />
                <h3 className="title">{node.header}</h3>
                <p className="subheader"></p>
                <div className="text">
                  {documentToReactComponents(node.text.json)}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="image-wrapper">
              <img src={node.image.fluid.src} alt="..." />
            </Col>
          </Row>
        ));
      }}
    />
  );
}
