import React from "react";
import { Col } from "react-bootstrap";

export default function ListItemBlock(props) {
  return (
    <Col lg={3} md={6} sm={12} className="feature feature_Testemonial">
      <img src={props.icon} alt={props.header} />
      {/* <h3 className="title">{props.header}</h3> */}
      <p>{props.text}</p>
      <p className="reviewer_text">-{props.reviewer}</p>
    </Col>
  );
}
