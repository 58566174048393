import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";

export default function ProductBlock() {
  return (
    <StaticQuery
      query={graphql`
        {
          product: contentfulProductBlock {
            header
            subheader
            image {
              fluid {
                src
              }
            }
            buttonText
            buttonLink
          }
        }
      `}
      render={data => (
        <Row className="product-section">
          <div className="product-section__content">
            <Row className="justify-content-between">
              <Col xs={12} md={5}>
                <div className="title">
                  <span>{data.product.header}</span>
                </div>
                <div className="content">
                  <span>{data.product.subheader}</span>
                </div>
                <Link to={data.product.buttonLink}>
                  <div className="btn btn-green">
                    <span>{data.product.buttonText}</span>
                  </div>
                </Link>
              </Col>
              <Col xs={12} md={7}>
                <img
                  className="product-interface"
                  src={data.product.image.fluid.src}
                  alt={`${data.product.header}`}
                />
              </Col>
            </Row>
          </div>
        </Row>
      )}
    />
  );
}
