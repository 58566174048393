import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function QuoteBlock() {
  return (
    <StaticQuery
      query={graphql`
        {
          quotes: allContentfulQuoteWithImage {
            nodes {
              id
              author
              avatar {
                file {
                  url
                }
              }
              date
              image {
                fluid {
                  src
                }
              }
              quoteText {
                json
              }
            }
          }
        }
      `}
      render={data => {
        return data.quotes.nodes.map(node => (
          <Row className="quote-block" key={node.id}>
            <Col xs={12} md={6} className="image-wrapper">
              <img src={node.image.fluid.src} alt="..." />
            </Col>
            <Col xs={12} md="6" className="text-wrapper ">
              <div className="content">
                <h3 className="date">{new Date(node.date).toDateString()}</h3>
                <div className="quote">
                  {documentToReactComponents(node.quoteText.json)}
                </div>
                <img
                  className="avatar"
                  src={node.avatar.file.url}
                  alt="avatar"
                />
                <p className="author">{node.author}</p>
              </div>
            </Col>
          </Row>
        ));
      }}
    />
  );
}
