import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>;
    },
  },
};

export default function HeroBlock() {
  return (
    <StaticQuery
      query={graphql`
        {
          hero: contentfulHeroBlock {
            header
            subheader
            image {
              fluid(maxWidth: 2400, maxHeight: 800, quality: 100) {
                src
                srcSet
              }
              title
              description
            }
            heroEditableText {
              json
            }
            mobileImage {
              fluid(maxWidth: 2400, quality: 100, maxHeight: 800) {
                src
                srcSet
              }
            }
            colorPicker
          }
        }
      `}
      render={(data) => (
        <Container fluid className="hero"
          style={{
            backgroundImage: `url(${data.hero.image.fluid.src})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}>
          <img src={data.hero.mobileImage.fluid.src} className="hero-mobile" />
          <Container>
            <Row>

              <Col xs={12} className=" hero-text">
                <h1 className="hero-text__header" style={{ color: `#${data.hero.colorPicker}` }}>
                  {data.hero.header}
                </h1>
                <h2 className="hero-text__subheader">
                  <span>{data.hero.subheader}</span>
                </h2>
                <div
                  className="hero-text__content"
                  style={{ color: `#${data.hero.colorPicker}` }}
                >
                  {documentToReactComponents(
                    data.hero.heroEditableText.json,
                    RICHTEXT_OPTIONS
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    />
  );
}
