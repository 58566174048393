import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";

export default function CallToActionBlock(props) {
  return (
    <Col lg={4} md={4} sm={12} className="feature cta">
      <h3 className="title">{props.header}</h3>
      <p>
        <span className="subtitle">{props.subheader}</span>
      </p>
      {/* <p>{props.text}</p> */}
      <Link to={props.buttonLink}>
        <div className="btn btn-tertiary">
          <span>{props.buttonText}</span>
        </div>
      </Link>
    </Col>
  );
}
