import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import BlogPostCard from "./partials/BlogPostCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import "../../utils/font-awesome";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <>{children}</>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>;
    },
  },
};

export default function LastBlogs() {
  return (
    <StaticQuery
      query={graphql`
        {
          blogposts: allContentfulBlogPostPage(
            limit: 3
            sort: { fields: [date], order: ASC }
          ) {
            nodes {
              slug
              image {
                fluid(quality: 100, maxHeight: 800, maxWidth: 2400) {
                  src
                }
              }
              createdAt
              header
              subheader
              mainText {
                json
              }
            }
          }
          CardsContainer: contentful5LastBlogs {
            header
          }
        }
      `}
      render={(data) => {
        return data.blogposts.nodes.length ? (
          <div className="blogposts-block">
            <h3 className="title">{data.CardsContainer.header}</h3>
            <Row>
              {data.blogposts.nodes.map((blogPost, i) => {
                return (
                  <Col className="card-wrapper">
                    <Link to={`/Blogs/${blogPost.slug}`} key={i}>
                      <BlogPostCard
                        imgSrc={blogPost.image.fluid.src}
                        header={blogPost.header}
                        subheader={blogPost.subheader}
                        key={`blog-${blogPost.id}`}
                        slug={blogPost.slug}
                        contentStart={documentToReactComponents(
                          blogPost.mainText.json,
                          RICHTEXT_OPTIONS
                        )}
                        createdAt={blogPost.createdAt}
                      />
                    </Link>
                  </Col>
                );
              })}
            </Row>
            <div className="more_blogs_btn_div">
              <Link to="/Blogs/" className=" readMoreBlogs">
                Flere blogs...{" "}
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  title="chevron-right"
                  size="sm"
                />{" "}
                {/* <i class="fas fa-chevron-right"></i> */}
              </Link>
            </div>
          </div>
        ) : (
            <div> No blogposts</div>
          );
      }}
    />
  );
}
