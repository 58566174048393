import React from "react";
import { Row } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import pinIcon from "../../images/promo_icon.svg";

export default function PromoBlock() {
  return (
    <StaticQuery
      query={graphql`
        {
          promo: contentfulPromoBlock {
            header
            text {
              text
            }
            image {
              fluid {
                src
              }
            }
          }
        }
      `}
      render={data => (
        <Row className="promo-block">
          <img className="icon" src={pinIcon} alt="..." />
          <h3>{data.promo.header}</h3>
          <p className="description">{data.promo.text.text}</p>
          <img
            className="promo-picture"
            src={data.promo.image.fluid.src}
            alt={data.promo.header}
          />
        </Row>
      )}
    />
  );
}
