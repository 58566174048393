import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBlock from "../components/frontpage/ContentfulHeroBlock";
import ProductBlock from "../components/frontpage/ContentfulProductBlock";
import FeatureBlock from "../components/frontpage/ContentfulFeatureBlock";
import PromoBlock from "../components/frontpage/ContentfulPromoBlock";
import ImageTextBlock from "../components/frontpage/ContentfulImageTextBlock";
import QuoteBlock from "../components/frontpage/ContentfulQuoteBlock";
import LastBlogs from "../components/frontpage/ContentfulLastBlogs";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//CSS
import "../css/main.css";

export const query = graphql`
  {
    landingPage: allContentfulLandingPage {
      nodes {
        title
        sections {
          ... on Node {
            ... on ContentfulFeatureBlock {
              id
            }
            ... on ContentfulHeroBlock {
              id
            }
            ... on ContentfulImageWithTextBlock {
              id
            }
            ... on ContentfulProductBlock {
              id
            }
            ... on ContentfulPromoBlock {
              id
            }
            ... on ContentfulQuoteWithImage {
              id
            }
            ... on Contentful5LastBlogs {
              id
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title={data.landingPage.nodes[0].title} />
    {data.landingPage.nodes[0].sections.map((section) => {
      if (section.__typename === "ContentfulHeroBlock") {
        return <HeroBlock key={`key-${section.id}`} />;
      } else if (section.__typename === "ContentfulFeatureBlock") {
        return <FeatureBlock key={`key-${section.id}`} id={section.id} />;
      } else if (section.__typename === "ContentfulImageWithTextBlock") {
        return <ImageTextBlock key={`key-${section.id}`} />;
      } else if (section.__typename === "ContentfulProductBlock") {
        return <ProductBlock key={`key-${section.id}`} />;
      } else if (section.__typename === "ContentfulPromoBlock") {
        return <PromoBlock key={`key-${section.id}`} />;
      } else if (section.__typename === "ContentfulQuoteWithImage") {
        return <QuoteBlock key={`key-${section.id}`} />;
      } else if (section.__typename === "Contentful5LastBlogs") {
        return <LastBlogs key={`key-${section.id}`} />;
      }
    })}
  </Layout>
);

export default IndexPage;
